export default function phoneConcat() {
    (function($) {
            // Phone Concatenation Script For Tracking
            setTimeout(function () {
                $('.tel').each(function () {
                    var unsliced = $(this).text();
                    var sliced = unsliced.slice(0, -2) + "...";
                    $(this).text(sliced);
                    var linked = "tel:" + unsliced.replace(/\s/g, '');
                    $(this).click(function () {
                        if ($(window).width() < 1000) {
                            window.location.href = linked;
                        } else {
                            $(this).text(unsliced);
                        }
                    });
                });
    
            }, 2000)
    })( jQuery );
}
